<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="10">
        <eb-date-range v-model="dateRange" @change="changeDate"></eb-date-range>
      </el-col>

      <el-col :span="4">
        <el-select
          v-model="listQuery.status"
          placeholder="状态"
          clearable
          @change="getList(1)"
        >
          <el-option
            v-for="(item, index) in this.const.BICYCLE_ORDER_STATUS"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col span="4">
        <eb-filter-user-list v-model="listQuery.userId" @change="getList(1)">
        </eb-filter-user-list>
      </el-col>

      <el-col :span="4">
        <el-input
          v-model="listQuery.batteryMacId"
          placeholder="电池编号"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        label="代理商名称"
        prop="operationName"
      ></el-table-column>

      <el-table-column label="用户名" prop="userName"></el-table-column>

      <el-table-column label="车牌号" prop="bicycleMacId"></el-table-column>

      <el-table-column label="电池编号" prop="batteryMacId"></el-table-column>

      <el-table-column label="金额" prop="amout">
        <template #default="scope">
            <span v-if="scope.row.amount">{{ scope.row.amount / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="状态" prop="status">
        <template #default="scope">
            <eb-badge
            :list="this.const.BICYCLE_ORDER_STATUS"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="骑行里程" prop="mil"></el-table-column>

      <el-table-column label="骑行开始时间" prop="startTime"></el-table-column>

      <el-table-column label="骑行结束时间" prop="endTime"></el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>
  
  <script>
import { mapState } from "vuex";
import { fetchBicycleOrderPage } from "@/api/bicycle";
import moment from "moment";

export default {
  computed: mapState({
    stationList: (state) => {
      return state.station.stationList || { list: [], map: {} };
    },
  }),
  data() {
    let startTime = moment()
      .subtract(7, "d")
      .startOf("d")
      .format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("d").format("YYYY-MM-DD HH:mm:ss");

    return {
      dateRange: [startTime, endTime],
      dataSource:[],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      listQuery: {},
    };
  },
  mounted() {
    this.getList(1);
    this.$store.dispatch("fetchStationAll");
  },

  methods: {
    changeDate(e) {
      if (!this.validatenull(e)) {
        this.listQuery.startTime = moment(e[0]).format("YYYY-MM-DD HH:mm:ss");
        this.listQuery.endTime = moment(e[1]).format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.listQuery.startTime = "";
        this.listQuery.endTime = "";
      }
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchBicycleOrderPage({ ...this.listQuery, ...this.pages }).then(
        (res) => {
          let list = res.data.data.records || [];
          this.total = res.data.data.total;
          console.log(list,'=-========list');
          this.dataSource = list;
        }
      );
    },
  },
};
</script>